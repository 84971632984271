<template>
    <div style="background-color: #efefef;margin: 0;">
        <header class="submit-header">
            <div class="img_logo">
                <img src="../assets/images/minipass_logo2.png" alt="" />
                &nbsp;
                <img src="../assets/images/survey_logo.png" alt="" />
            </div>
        </header>
        <section class="submit-container">
            <div class="card hot-pot">
                <div class="line-blue"></div>
                <div class="hot-item">
                    <img id="slogo" src="https://d3puj4wh202ha4.cloudfront.net/s_logo_1639162918017.png" alt="">
                    <div class="hot-text">
                        <p class="blue">{{ survey_info && survey_info.task_name || ''}}</p>
                    </div>
                </div>
            </div>
            <form name="submit_form" v-if="survey_info.custom_question" class="question_container">
                <div class="card" v-bind:key="item.name" v-for="item, index in survey_info.custom_question" :custom-name="item.name" :custom-type="item.type">
                    <div class="card-mask" v-if="item.type === 'choice' || item.type === 'multiple_choice' || item.type === 'rating'"></div>
                    <div class="title">
                        <h4>{{ item.is_required ?  ' * ' + (index + 1) + '. ' + item.content : (index + 1) + '. ' + item.content }}</h4>
                    </div>
                    <div class="radio-box" v-if="item.type === 'choice' || item.type === 'multiple_choice' || item.type === 'rating'">
                        <div v-if="item.type === 'choice' || item.type === 'multiple_choice'">
                            <div class="radio-item wave-box" v-bind:key="choice_item + '_' + index" v-for="choice_item, choice_index in item.answer" :id="item.type + index">
                                <div :class="{ 'm-radio': item.type === 'choice', 'm-checkbox': item.type === 'multiple_choice', 'wave': true }">
                                    <span class="big-circle"><b></b><b></b></span>
                                    <input v-if="item.type === 'choice'" :id="item.type + index + choice_index" :name="item.type + index" type="radio" :value="choice_item" />
                                    <input v-if="item.type === 'multiple_choice'" :id="item.type + index + choice_index" :name="item.type + index + choice_index" type="checkbox" :value="choice_item" />
                                    <span class="m-icon m-radio-icon"></span>
                                </div>
                                <label class="m-label" :for="item.type + index + choice_index">{{ choice_item }}</label>
                            </div>
                        </div>
                        <div v-else-if="item.type === 'rating'" class="radio-box assess" :id="item.type + index">
                            <div class="radio-item" v-bind:key="rating_item + '_' + index" v-for="rating_item, rating_index in item.star_count">
                                <div class="m-radio">
                                    <input @change="handleRatingChange('rating' + index, rating_index)" :id="item.type + index + rating_index" :name="item.type + index" type="radio" :value="rating_item">
                                    <span class="m-icon m-radio-icon" :class="{'blue-start': answer_dict['rating' + index] && answer_dict['rating' + index] >= rating_index}"></span>
                                </div>
                                <label :for="item.type + index + rating_index">{{ rating_item }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="content" v-else>
                        <input :id="item.type + index" v-if="item.type == 'text'" class="text-input mdc-text-field__input" type="text">
                        <textarea :id="item.type + index" v-else-if="item.type == 'textarea'" class="suggestion mdc-text-field__input" placeholder="" aria-label="" rows="2" cols="40"></textarea>
                    </div>
                </div>
                <div class="card" custom-name="q1" custom-type="choice" v-if="false">
                    <div class="card-mask"></div>
                    <div class="title">
                        <h4>复选框 </h4>
                    </div>
                    <div class="radio-box">
                        <div class="radio-item wave-box">
                            <div class="m-checkbox wave">
                                <span class="big-circle"><b> </b><b> </b></span>
                                <input id="custom_choice001" name="custom_choice001" type="checkbox" value="1">
                                <span class="m-icon m-checkbox-icon"></span>
                            </div>
                            <label class="m-label" for="custom_choice001">1</label>
                        </div>
                        <div class="radio-item wave-box">
                            <div class="m-checkbox wave">
                                <span class="big-circle"><b> </b><b> </b></span>
                                <input id="custom_choice002" name="custom_choice002" type="checkbox" value="2">
                                <span class="m-icon m-checkbox-icon"></span>
                            </div>
                            <label class="m-label" for="custom_choice002">2</label>
                        </div>
                        <div class="radio-item wave-box">
                            <div class="m-checkbox wave">
                                <span class="big-circle"><b> </b><b> </b></span>
                                <input id="custom_choice003" name="custom_choice003" type="checkbox" value="3">
                                <span class="m-icon m-checkbox-icon"></span>
                            </div>
                            <label class="m-label" for="custom_choice003">3</label>
                        </div>
                    </div>
                </div>
                <div class="submit-card">
                    <div class="card-mask"></div>
                    <div class="button-item">
                        <button :style="{'color': has_submitted ? 'grey' : 'white'}" :disabled="has_submitted" id="save" type="button" @click="doSubmit">
                            <span>{{ has_submitted ? 'Submitted' : 'Submit'}}</span>
                        </button>
                    </div>
                </div>
            </form>
        </section>
        <footer>
            <p>powered by<a href="https://minitable.net/" target="_blank"> <img src="../assets/images/minitable_icon.png" alt=""></a></p>
            <p>
                <span>By joining in, you agree to Minitable Tech, Inc &nbsp;</span>
                <a href="http://info.minitable.net/termsofuse.html" target="_blank">Terms of Service </a>
                <span> &nbsp;and &nbsp; </span>
                <a href="http://info.minitable.net/privacypolicy.html" target="_blank">Privacy Policy</a>
            </p>
        </footer>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import Swal from 'sweetalert2';

    export default {
        name: 'Survey',
        data() {
            return {
                task_id: this.$route.params.id || '',
                phone_num: this.$route.query.phone || '',
                is_loading: false,
                answer_dict: {},
                has_submitted: false
            };
        },
        async created() {
            if (this.task_id) {
                this.is_loading = true;
                await this.getSurveyInfo(this.task_id);
                this.is_loading = false;
            }
        },
        watch: {},
        mounted() {
        },
        computed: {
            ...mapGetters({
                survey_info: 'survey/get_survey_info'
            }),
            ...mapState({}),
        },
        methods: {
            ...mapActions({
                getSurveyInfo: 'survey/getSurveyInfo',
                submitSurvey: 'survey/submitSurvey'
            }),
            ...mapMutations({}),
            handleRatingChange(id, index) {
                this.$set(this.answer_dict, id, index);
            },
            async doSubmit() {
                if (this.has_submitted) {
                    return;
                }
                
                let custom_questions = this.survey_info.custom_question;
                let answers = [];

                for (let i = 0; i < custom_questions.length; i++) {
                    let { type, name, is_required } = custom_questions[i];

                    if (type === 'choice' || type === 'multiple_choice') {
                        let tag_name = type + i;
                        let one_ans = [];
                        document.getElementsByName(tag_name).forEach((item) => {
                            if (item.checked) {
                                one_ans.push(item.value);
                            }
                        });

                        if (is_required && one_ans.length === 0) {
                            Swal.fire({
                                title: 'Please answer the No.' + (i + 1) + ' question',
                                icon: 'error',
                                confirmButtonText: 'OK'
                            });
                            return;
                        }

                        answers.push({
                            [name]: one_ans.join('-_-')
                        })
                    } else if (type === 'rating') {
                        let tag_name = type + i;
                        let one_ans = this.answer_dict[tag_name];

                        if (one_ans === undefined) {
                            one_ans = '';
                        }

                        if (is_required && one_ans === '') {
                            Swal.fire({
                                title: 'Please answer the No.' + (i + 1) + ' question',
                                icon: 'error',
                                confirmButtonText: 'OK'
                            });
                            return;
                        }

                        answers.push({
                            [name]: one_ans
                        });
                    } else {
                        let tag_name = type + i;
                        let one_ans = document.getElementById(tag_name).value;

                        if (is_required && !one_ans) {
                            Swal.fire({
                                title: 'Please answer the No.' + (i + 1) + ' question',
                                icon: 'error',
                                confirmButtonText: 'OK'
                            });
                            return;
                        }

                        let regex = custom_questions[i].regex;
                        if (regex) {
                            regex = new RegExp(regex);

                            if (!regex.test(one_ans)) {
                                Swal.fire({
                                    title: 'Please enter the correct format for the No.' + (i + 1) + ' question',
                                    icon: 'error',
                                    confirmButtonText: 'OK'
                                });
                                return;
                            }
                        }

                        answers.push({
                            [name]: one_ans
                        });
                    }

                    console.log(answers);
                }
                
                let res = await this.submitSurvey({
                    task_id: this.task_id,
                    phone_num: this.phone_num,
                    answers: JSON.stringify(answers)
                });

                if (res && res.code === 100000) {
                    Swal.fire({
                        title: 'Submit successfully',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });

                    this.has_submitted = true;
                } else {
                    Swal.fire({
                        title: 'Submit failed',
                        content: res.msg,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                
                }
            }
        }
    };
</script>

<style>
    .submit-header {
        height: 4.0625rem;
        padding: 1.5rem 0 0;
        box-sizing: border-box;
        background-color: #7427FF;
        background-size: contain;
        background-position: right;
        position: relative;
    }
    .submit-header h3 {
        display: none;
    }
    .submit-header .img_logo {
        display: block;
        width: 11rem;
        margin: 0 auto;
    }

    .submit-header img {
        height: 1.0625rem;
    }
    .submit-header .language {
        display: block;
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
        background-color: #fff;
        border-radius: 0.3125rem;
        color:#324CA3;
        font-size: 1rem;
        right: 2rem;
        top: 1.28125rem;
        font-weight: 500;
        -webkit-transform: scale(0.45);
        transform: scale(0.45);
    }
    .submit-container {
        max-width: 23.4375rem;
        margin: 0 auto;
    }
    .card {
        width: 100%;
        padding: 0.625rem;
        position: relative;
        background-color: #fff;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
        border-radius: 0.25rem;
        font-size: 0.875rem;
        overflow: hidden;
        box-sizing: border-box;
        margin-top: 0.625rem;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
    .card-mask {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
    }
    .card.hot-pot {
        padding: 0;
        text-align: center;
    }
    .hot-pot .line-blue {
        border-top: 0.0625rem solid #707070;
        border-bottom: 0.0625rem solid #707070;
        height: 0.25rem;
        background-color: #324CA3;
    }
    .hot-pot img {
        width: 3.3125rem;
        margin-bottom: 0.75rem;
    }
    .hot-pot .hot-item {
        padding: 0.75rem 0.75rem 0.625rem;
    }
    .hot-pot p {
        color: #555;
        line-height: 1.5rem;
    }
    .hot-pot .blue {
        color: #324CA3;
        font-weight: 600;
        font-size: 1.0625rem;
    }
    .card .title {
        line-height: 1.75rem;
    }
    .card .two-title {
        padding: 0.375rem 0;
    }
    .card .two-title .title, .card .two-title input[type=text] {
        line-height: 1.25rem;
        border-bottom: none;
    }
    .card .title input[type=text] {
        border: none;
        font-size: 1rem;
    }
    .card .title input::-webkit-input-placeholder {
        color: #4a4a4a;
    }
    .card .title input:-ms-input-placeholder {
        color: #4a4a4a;
    }
    .card .title input::-moz-placeholder {
        color: #4a4a4a;
        opacity: 1;
    }
    .card .title input:-moz-placeholder {
        color: #4a4a4a;
        opacity: 1;
    }
    .card .title h4 {
        line-height: 1.25rem;
        font-size: 1rem;
    }

    .card input[type=text] , .card input[type=number], .card .favorite_food, .card .custom_textarea {
        display: block;
        width: 100%;
        line-height: 2rem;
        font-size: 0.875rem;
        border: none;
        border-bottom: 0.0625rem solid #e2e2e2;
    }

    .card textarea.suggestion {
        display: block;
        width: 100%;
        height: 5.625rem;
        padding: 0.5rem 0.75rem;
        border: 0.0625rem solid #e2e2e2;
        box-sizing: border-box;
        font-size: 0.875rem;
        margin: 0.625rem 0;
    }
    .card .favorite_food, .card .custom_textarea {
        padding: .5rem 0;
        line-height: 1.5rem;
    }

    .card .radio-box {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .card .radio-item {
        display: flex;
        height: 1.125rem;
        line-height: 1.125rem;
        margin-top: 0.5rem;
        margin-right: 0.875rem;
    }
    .card .select-item {
        position: relative;
    }
    .card select {	
        width: 5.875rem;
        height: 2rem;
        padding-left: 0.625rem;
        font-size: 0.875rem;
        border: 0.0625rem solid #e2e2e2;
        box-sizing: border-box;
        margin-right: 0.9375rem;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: url(../assets/images/arrow.png);
        background-repeat: no-repeat;
        background-position: right 0.5rem center;
        background-size: 0.5rem auto;
    }
    .card select option {
        color: #4a4a4a;
    }

    .card .birthday {
        padding: 0.625rem 0;
        display: flex;
    }
    .card .tel {
        display: -webkit-flex;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
    .card .tel select {
        width: 3.875rem;
    }
    .card .tel-number {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .card .assess {
        width: 100%;
        overflow: hidden;
        margin: 0.75rem 0 0.375rem;
    }

    .m-icon {
        display: block;
        width: 0.75rem;
        height: 0.75rem;
        position: absolute;
        box-sizing: content-box;
        top: 0.1875rem;
        left: 0;
    }
    .m-icon::before, .m-icon::after {
        content: '';
        display: block;
        position: absolute;
    }
    .m-radio, .m-checkbox {
        position: relative;
        padding-left: 1.25rem;
    }
    .m-radio input, .m-checkbox input {
        position: absolute;
        opacity: 0;
        top: 0.125rem;
        left: 0;
        z-index: 1;
    }
    .m-radio-icon {
        border: 0.0625rem solid #9d9d9d;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #fff;
    }
    .m-radio-icon::after {
        width: 0.5rem;
        height: 0.5rem;
        background-color: none;
        top: 0.125rem;
        left: 0.125rem;
        -webkit-border-radius: 50%;
        border-radius: 50%;
    }
    .m-radio input:checked + span.m-icon {
        border-color: #324CA3;
    }
    .m-radio input:checked + span.m-icon::after {
        background-color: #324CA3;
    }

    .wave-box {
        display: block;
        position: relative;
    }
    .radio-item.wave-box {
        padding-left: 2.5rem;
        height: 2rem;
    }
    .m-checkbox.wave, .m-radio.wave {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: -0.625rem;
        left: 0;
    }
    .m-checkbox.wave:hover, .m-radio.wave:hover{
        background-color: rgba(50, 76, 163, 0.1);
    }
    .m-checkbox.wave .big-circle, .m-radio.wave .big-circle{
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    .wave-box .join {
        padding-left: 2.5rem;
        font-size: 0.875rem;
    }

    .m-radio.wave input {
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 3;
    }
    .m-radio.wave .m-radio-icon {
        width: 0.875rem;
        height: 0.875rem;
        top: 0.75rem;
        left: 0.75rem;
        z-index: 2;
    }
    .m-radio.wave .m-radio-icon::after {
        width: 0.625rem;
        height: 0.625rem;
        z-index: 2;
    }

    .big-circle b {
        width: 20%;
        height: 20%;
        border-radius: 50%;
        display: block;
        background-color: rgba(255, 255, 255, 0.6);
        position: absolute;
        left: 40%;
        top: 40%;
        z-index: 1;
    }

    .big-circle b.input-checked {
        -webkit-animation: waveAnimation .5s linear;
        animation: waveAnimation .5s linear 1;
        background-color: #324CA3;
    }

    .big-circle b.input-off {
        -webkit-animation: waveAnimation2 .5s linear;
        animation: waveAnimation2 .5s linear 1;
        background-color: #06174e;
    }


    @-webkit-keyframes waveAnimation {
        0% {
            opacity: .8;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        100% {
            opacity: 0;
            -webkit-transform: scale(5);
            transform: scale(5);
        }
    }

    @keyframes waveAnimation {
        0% {
            opacity: .8;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        100% {
            opacity: 0;
            -webkit-transform: scale(5);
            transform: scale(5);
        }
    }
    @-webkit-keyframes waveAnimation2 {
        0% {
            opacity: .8;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        100% {
            opacity: 0;
            -webkit-transform: scale(5);
            transform: scale(5);
        }
    }

    @keyframes waveAnimation2 {
        0% {
            opacity: .8;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        100% {
            opacity: 0;
            -webkit-transform: scale(5);
            transform: scale(5);
        }
    }

    .m-checkbox.wave input , .m-checkbox-icon{
        display: block;
        width: 50%;
        height: 50%;
        top: 25%;
        left: 25%;
        cursor: pointer;
    }
    .m-checkbox.wave input{
        z-index: 3;
    }
    .m-checkbox-icon {
        box-sizing: border-box;
        border: 0.0625rem solid #d0d0d0;
        background-color: #fff;
        z-index: 2;
    }
    .m-checkbox input:checked + span.m-icon {
        background-image: url(../assets/images/tick-blue.png);
        background-repeat: no-repeat;
        background-size: 0.75rem auto;
        background-position: center;
    }


    .card .assess label {
        display: none;
    }
    .card .assess span {
        display: block;
        border: none;
        width: 1.75rem;
        height: 1.75rem;
        margin-right: 0.9375rem;
        background-image: url(../assets/images/start.png);
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .card .assess input:checked + span, .card .assess span.blue-start {
        background-image: url(../assets/images/start_cur.png);
    }
    .assess .m-radio input:checked + span {
        border: none;
    }
    .card .assess span::after, .card .assess span::before {
        display: none;
    }
    .card .assess .m-radio {
        display: block;
        padding-left: 0;
        width: 1.75rem;
        height: 1.875rem;
    }
    .card .assess .radio-item {
        height: 1.875rem;
        margin-top: 0;
        width: 2.75rem;
    }
    .card .assess .m-radio input {
        width: 1.75rem;
        height: 1.75rem;
    }


    .submit-card {
        text-align: center;
    }
    .submit-card button {
        width: 11.25rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 0.9375rem;
        font-weight: 600;
        background-color: #324CA3;
        color: #fff;
        border: none;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
        border-radius: 0.25rem;
        text-transform: uppercase;
        user-select: none;
    }
    .submit-card button span {
        letter-spacing: 0.125rem;
    }
    .submit-card {
        padding: 1.5rem 2.25rem 0.5rem;
        position: relative;
    }
    .deal-item {
        margin-top: 1rem;
        display: flex;
        text-align: left;
    }


    footer {
        width: 200%;
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        margin-left: -50%;
        line-height: 2rem;
        text-align: center;
        vertical-align: bottom;
    }
    footer img {
        width: auto;
        height: 1.75rem;
        margin-bottom: -3px;
    }

    .new_sweet_alert.sweet-alert {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin: 0 !important;
        left: 0;
        top: 0;
        background: url(../assets/images/minitable_new.png) no-repeat #fff;
        background-size: auto 1.2rem;
        background-position: center 94vh;
    }
    .new_sweet_alert.sweet-alert h2 {
        color: #324CA3;
    }
    .new_sweet_alert.sweet-alert p {
        color: #324CA3;
        font-weight: 500;
    }
    .new_sweet_alert.sweet-alert .sa-icon.sa-success .sa-placeholder, .new_sweet_alert.sweet-alert .sa-icon.sa-success .sa-line {
        border-color: #AAEC99;
    }
    .new_sweet_alert.sweet-alert[data-has-cancel-button=false] button {
        background-color: #fff !important;
        color: #324CA3;
        text-decoration: underline;
        margin-top: 76px;
    }

    .new_sweet_alert.sweet-alert[data-has-cancel-button=false] .sa-button-container {
        padding-bottom: 10rem;
        background: url(../assets/images/button.png) no-repeat #fff;
        background-size: auto 1.6rem;
        background-position: center bottom;
    }
</style>
