import fetchApi from '../../kits/newFetch';
import i18n from '../../locales';
import { isValidStr } from '../../kits/tools';

let lock_aj = false;
let edit_cart_lock_aj = false;

// initial state
const state = {
    sid: '',
    sname: '',
};

// getters
const getters = {
    
};

// actions
const actions = {
    initData({ commit, rootState, rootGetters }, sid) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/takeout/store/get?sid=' + (state.sid || sid), {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
            });
    }
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, data) {
        
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
