<template>
    <div id="app_center">
        <div class="join_us">
            <div class="join_us_bg"></div>
            <div class="join_header"></div>
            <div class="join_content">
                <div class="profile_main">
                    <img :src="store_info && (store_info.feature_image || no_image)">
                    <h2 class="fy_bolder"> {{ store_info && store_info.sname || '' }} </h2>
                    <p class="profile_tel">{{ store_info && store_info.stel || 'unknown' }}</p>
                    <p class="profile_address">{{ address }}</p>
                    <div class="profile_card" v-if="store_info && store_info.waitlist_btn_show">
                        <h2 class="fy_bold">Waitlist</h2>
                        <div class="profile_info" v-if="store_info.waitlist_policy_enable">{{ store_info.waitlist_policy }}</div>
                        <div class="profile_btn" v-if="store_info.waitlist_btn_show">
                            <a class="" :href="store_info.waitlist_url">
                                <i class="link_wait"></i>
                                <span>Join the waitlist</span>
                            </a>
                        </div>
                    </div>
                    <div class="profile_card" v-if="store_info && store_info.reserve_btn_show">
                        <h2 class="fy_bold">Reservation</h2>
                        <div class="profile_info" v-if="store_info.reserve_policy_enable">{{ store_info.reserve_policy }}</div>
                        <div class="profile_btn">
                            <a class="" :href="store_info.reserve_url">
                                <i class="link_reseve"></i>
                                <span>Reserve a table</span>
                            </a>
                        </div>
                    </div>
                    <div class="profile_card" v-if="store_info && store_info.pass_btn_show">
                        <h2 class="fy_bold">Pass</h2>
                        <div class="profile_info" v-if="store_info.pass_policy_enable">{{ store_info.pass_policy }}</div>
                        <div class="profile_btn">
                            <a class="" href="javascript:;" @click="go_to_app">
                                <i class="link_pass"></i>
                                <span>Become a member for free</span>
                            </a>
                        </div>
                    </div>
                    <div class="profile_link">
                        <a href="javascript:;">
                            Get more infomation with Minipass
                        </a>
                    </div>
                </div>
            </div>
            <div class="join_footer" style="margin-top: 2.5rem; margin-bottom: 7rem">
                <div class="footer_left">
                    <span class="footer_phone"></span>
                    <span class="footer_text ft_puper">
                        Manage your dining experience easily
                    </span>
                </div>
                <div class="footer_right">
                    <a class="down_btn" @click="banner_to_store" href="javascript:;">Download</a>
                </div>
            </div>
            <div class="profile_down">
                <div class="footer_left">
                    <span class="footer_phone"></span>
                    <span class="footer_text ft_puper">
                        Get more information with Minipass
                    </span>
                </div>
                <div class="footer_right">
                    <a class="down_btn no_icon" @click="fixed_layer_to_store" href="javscript:;">Download</a>
                    <a class="down_btn" href="javascript:;" @click="fixed_layer_to_app">Open app</a>
                </div>
            </div>
        </div>
        <div class="pop" :style="{display: show_download_layer ? 'block' : 'none'}">
            <div class="pop_main invitation" style="top: 16rem;">
                <div class="invite_main">
                    <h2 class="fy_bold" style="font-size: 1rem; text-align: left;">To Join the membership, please download Minipass at App Store.</h2>
                    <a class="invite_btn puper_btn" href="javascript:;" @click="do_accept_download">Download</a>
                    <a class="invite_btn" href="javascript:;" @click="close_download_layer">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import OpenApp from 'web-open-app';
    import Swal from 'sweetalert2';
    import user_img from '../assets/images/user_icon.png';
    import no_image from '../assets/images/no_image.png';

    export default {
        name: 'profile',
        components: {
        },
        data() {
            return {
                sid: this.$route.params.id || '',
                no_image,
                is_wexin: navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1,
                show_download_layer: false
            };
        },
        async created() {
            if (this.sid) {
                this.getStoreProfileInfo(this.sid);
            }
        },
        watch: {
            store_info(){
                if (this.store_info) {
                    this.render_share();
                }
            }
        },
        mounted() {
            gtag("event", "r_profile_share_pv", {
                bid: this.sid
            });
        },
        computed: {
            ...mapGetters({
                store_info: 'store/get_store_info',
            }),
            ...mapState({
            }),
            address() {
                if (this.store_info) {
                    return this.store_info.street_address + ', ' + this.store_info.locality + ', ' + this.store_info.postal_code;
                }

                return '';
            }
        },
        methods: {
            ...mapActions({
                getStoreProfileInfo: 'store/getStoreProfileInfo'
            }),
            ...mapMutations({}),
            banner_to_store() {
                gtag("event", "r_profile_banner_to_store", {
                    bid: this.sid
                });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=r_profileH5_banner&mt=8');
            },
            fixed_layer_to_store() {
                gtag("event", "r_profile_fixed_layer_to_store", {
                    bid: this.sid
                });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=r_profileH5_download_button&mt=8');
            },
            fixed_layer_to_app() {
                gtag("event", "r_profile_fixed_layer_to_app", {
                    bid: this.sid
                });
                
                this.do_open_app();
            },
            do_open(_url) {
                window.open(_url || "https://apps.apple.com/cn/app/minipass-minitable/id1626268909?l=en")
            },
            do_open_app() {
                window.location = `https://app.minitable.net/review/?sid=` + this.sid + '&type=profile';

                setTimeout(() => {
                    this.$nextTick(() => {
                        Swal.fire({
                            title: 'Not installed yet?',
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'Download',
                            denyButtonText: `Cancel`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                gtag("event", "r_profile_popup_to_store", {
                                    bid: this.sid
                                });

                                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=r_profileH5_pop-up_download&mt=8');
                            }
                        })
                    });
                }, 2000);
            },
            render_share() {
                if (this.is_wexin) {
                    let link = window.location.href;
                    let img = 'https://d3puj4wh202ha4.cloudfront.net/static/minipass_logo.png';
                    this.wechat.share(`${this.store_info.sname} · Minipass`, `Your friend shares ${this.store_info.sname} with you`, link, img)
                }
            },
            go_to_app() {
                gtag("event", "profile_pass_button_click", {
                    bid: this.sid
                });

                window.location = `https://app.minitable.net/review/?sid=` + this.sid + '&type=profile&section=pass';

                setTimeout(() => {
                    this.$nextTick(() => {
                        this.show_download_layer = true;
                    });
                }, 2000);
            },
            close_download_layer() {
                this.show_download_layer = false;
            },
            do_accept_download() {
                gtag("event", "r_profile_layer_to_store", {
                    bid: this.sid
                });

                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=r_profileh5_pass_download&mt=8');
            },
        }
    };
</script>

<style>
    .join_us {
        padding: 2rem 1rem 0;
        position: relative;
        /* background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 180% auto; */
    }
    .join_us_bg {
        width: 100%;
        height: 25rem;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 160% auto;
        z-index: -1;
    }
    .join_header {
        margin: 0 auto;
        width: 7.5rem;
        height: 1.25rem;
        background-image: url('../assets/images/minipass_white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 2rem;
    }
    .join_content{
        background-color: #fff;
        text-align: center;
        border-radius: .75rem;
        padding-top: 0.75rem;
        box-shadow: 0px 8px 5px rgba(230, 230, 230, 0.4);
        /* padding-bottom: 1rem; */
    }
    .profile_main {
        padding: 0 .75rem .75rem;
    }
    .profile_main img {
        border-radius: .75rem;
        display: block;
        width: 100%;
    }
    .profile_main h2 {
        margin: .75rem 0;
        line-height: 1.75rem;
        text-align: left;
    }
    .profile_main p {
        padding-left: 1.25rem;
        text-align: left;
        color: #333;
        background-repeat: no-repeat;
        background-size: 1rem auto;
        background-position: left center;
        line-height: 1.5rem;
    }
    .profile_main .profile_address {
        background-image: url('../assets/images/address_icon.png');
    }
    .profile_main .profile_tel {
        background-image: url('../assets/images/phone_icon.png');
    }
    .profile_card {
        margin-bottom: 1.75rem;
    }
    .profile_card h2 {
        font-size: 1rem;
        color: #000;
    }
    .profile_info {
        text-align: left;
        color: #333;
        line-height: 1.5;
    }
    .profile_btn {
        margin-top: 1.75rem;
    }
    .profile_btn a {
        display: flex;;
        height: 2.5rem;
        line-height: 2.5rem;
        color: #fff;
        background-color: #7427ff;
        text-align: center;
        border-radius: 1.25rem;
        justify-content: center;
        font-size: .875rem;
        font-family: 'Poppins-SemiBold';
    }
    .profile_btn a i {
        display: inline-block;
        width: 2rem;
        height: 2.5rem;
        background-repeat: no-repeat;
        background-size: 1rem auto;
        background-position: left center;
    }
    .profile_btn a i.link_reseve {
        background-image: url('../assets/images/reseve_icon.png');
    }
    .profile_btn a i.link_wait {
        background-image: url('../assets/images/wait_icon.png');
    }
    .profile_btn a i.link_pass {
        background-image: url('../assets/images/pass_icon.png');
        background-size: auto 1.0625rem;
    }
    .profile_link a {
        color: #9ca4ae;
    }
    .profile_down {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        /* max-width: 768px; */
        bottom: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0px 8px 14px rgba(0,0,0, 0.15);
    }
    .profile_down .footer_left .footer_phone {
        border-radius: 10px;
        box-shadow: 0px 7px 14px rgba(0,0,0, 0.15);
    }
    .profile_down .footer_right{
        width: 16rem;
        display: flex;
        justify-content: space-between;
        transform: scale(.5);
        float: none;
        position: absolute;
        right: -3.5rem;
        top: 1rem;
    }
    .profile_down .down_btn {
        background: #7427FF;
        border: 2px solid #7427FF;
        padding: 0 .25rem;
        text-align: center;
        width: 7.5rem;
        height: 3.25rem;
        line-height: 3.25rem;
        font-size: 1.1875rem;
        box-sizing: border-box;
        margin-top: 0;
        font-family: 'Poppins-SemiBold';
    }
    .profile_down .down_btn.no_icon {
        background: #fff;
        color: #7427FF;
    }
    .join_footer {
        margin-top: 1rem;
        width: 100%;
        height: 5rem;
        background-color: #FFF4AE;
        background-image: url('../assets/images/yellow_bg.png');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        border-radius: 0.75rem;
        overflow: hidden;
    }
    .footer_left {
        float: left;
        height: 3rem;
        padding: 1rem .625rem;
    }
    .footer_left span {
        display: inline-block;
    }
    .footer_left .footer_phone {
        width: 3rem;
        height: 3rem;
        background-image: url('../assets/images/minipass_phone.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
    }
    .footer_left .footer_text {
        width: 8.5rem;
        float: right;
        margin-left: 0.625rem;
        font-size: 0.75rem;
        font-family: Poppins-Bold;
        margin-top: 0.5rem;
    }
    .profile_down .footer_left .footer_text {
        font-family: 'Poppins-SemiBold';
    }
    .footer_right {
        float: right;
        margin-right: .625rem;
    }
    .down_btn {
        margin-top: 1.5rem;
        display: block;
        width: 4.75rem;
        height: 2rem;
        line-height: 2rem;
        background-color: #7427FF;
        background-image: url('../assets/images/apple_icon.png');
        background-position: .5rem center;
        background-repeat: no-repeat;
        background-size: auto 50%;
        border-radius: 1.5rem;
        color: #fff;
        padding-left: 1.875rem;
    }
</style>
