<template>
    <div id="app_center">
        <div class="join_us">
            <div class="join_us_bg"></div>
            <div class="join_header"></div>
            <div class="join_content">
                <h2 class="fy_bold"> {{ store_info && store_info.sname }} </h2>
                <h3 class="ft_puper fy_bold">{{ waitlist_info && (waitlist_info.pre_tstatus == 0 || waitlist_info.pre_tstatus == 1 || waitlist_info.pre_tstatus == 6) ? 'The party is in queue' : ''}}</h3>
                <div class="join_banner">
                    <img src="../assets/images/queue_bg.png" />
                    <div class="color_line"></div>
                </div>
                <div class="join_invite">
                    <div class="invite">
                        <div class="invite_title fy_bold">
                            <span class="ft_puper">{{ invite_by || '' }} </span>
                            invites you to the party
                        </div>
                        <div class="invite_group">
                            <div class="invite_item" v-bind:key="item.id" v-for="item in user_list">
                                <div class="img_bg">
                                    <img :src="item.photo_url || user_img" />
                                </div>
                                <div>{{ item.name }}</div>
                            </div>
                            <div class="invite_item" @click="open_layer">
                                <div class="img_bg more"></div>
                                <div>Join</div>
                            </div>
                        </div>
                    </div>
                    <div class="ft_grey">Track the waitlist status by joining the party</div>
                </div> 
            </div>
            <div class="join_footer" @click="do_banner_open">
                <div class="footer_left">
                    <span class="footer_phone"></span>
                    <span class="footer_text ft_puper">
                        Manage your dining experience easily
                    </span>
                </div>
                <div class="footer_right">
                    <a class="down_btn" href="javascript:;">Download</a>
                </div>
            </div>
        </div>
        <div class="pop"  :style="{display: false && is_wexin ? 'block' : 'none'}">
            <div class="pop_arrow"></div>
            <div class="pop_main dash_border">
                <div class="share fy_bold">
                    Open the website
                    <br>
                    on your browser
                </div>
            </div>
        </div>
        <div class="pop" :style="{display: show_accept_layer ? 'block' : 'none'}">
            <div class="pop_main invitation">
                <div class="invite_main">
                    <h2 class="fy_bold">Invitation</h2>
                    <p>
                        <span class="ft_puper fy_bold">{{ invite_by || '' }}</span>
                        invites you to join the party
                    </p>
                    <p class="ft_puper">{{ store_info && store_info.sname }}</p>
                    <a class="invite_btn puper_btn" href="javascript:;" @click="doAccept">Accept</a>
                    <a class="invite_btn" href="javascript:;" @click="doCancel">Decline</a>
                </div>
            </div>
        </div>
        <div class="pop" :style="{display: show_download_layer ? 'block' : 'none'}">
            <div class="pop_main invitation" style="top: 16rem;">
                <div class="invite_main">
                    <h2 class="fy_bold" style="font-size: 1rem;">To join the party, please download Minipass App.</h2>
                    <a class="invite_btn puper_btn" href="javascript:;" @click="do_accept_download">Download</a>
                    <a class="invite_btn" href="javascript:;" @click="close_download_layer">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import OpenApp from 'web-open-app';
    // import Swal from 'sweetalert2';
    // import user_img from '../assets/images/user_icon.png';

    export default {
        name: 'waitlist',
        components: {
        },
        data() {
            return {
                user_img: 'https://d3puj4wh202ha4.cloudfront.net/minipass/ic_avatar_0.png',
                id: this.$route.params.id || '',
                token: this.$route.query.token || '',
                openapp: null,
                show_accept_layer: false,
                show_download_layer: false,
                phone: this.$route.query.phone || '',
                invite_by: '',
                is_wexin: navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1
            };
        },
        async created() {
            this.getWaitlistInfo(this.id);
            // document.title = 'invites you to join the party';
        },
        watch: {
            waitlist_info() {
                // if (this.waitlist_info && this.waitlist_info.pre_tid) {
                //     setTimeout(() => {
                //         this.open_layer();
                //     }, 1000);
                // }
            },
            user_list() {
                if (this.user_list && this.user_list.length > 0) {
                    for (let i = 0; i < this.user_list.length; i++) {
                        if (this.user_list[i].phone_num == atob(this.phone)) {
                            this.invite_by = this.user_list[i].name;
                            break;
                        }
                    }

                    // if (this.invite_by == '') {
                    //     this.invite_by = this.waitlist_info.customer_name || '';
                    //     document.title = this.invite_by + ' invites you to join the party';
                    // }

                    this.render_share();
                }
            }
        },
        mounted() {
            gtag("event", "waitlist_share_pv", {
                bid: this.id
            });
            
            const openapp = new OpenApp({
                // scheme地址
                scheme: `minipass://invite/?id=${this.id}&business=waitlist&token=${this.token}`,
                // intent地址
                intent: "",
                // android applink url or ios universal links url
                applink: `minipass://invite/?id=${this.id}&business=waitlist&token=${this.token}`,
                // applink: 'https://app.minitable.net/review/?sid=85a0dc31e266b4de1d1d9d6c77760531&type=profile',
                appstore: "https://apps.apple.com/cn/app/minipass-minitable/id1626268909?l=en",
                // iosUseScheme: true,
                // 复制到剪贴板数据
                copyText: "",
                // 唤起app状态回调
                callback: function (status, msg) {
                    console.log("status: ", status, "  msg: ", msg);
                }
            });
            
            this.openapp = openapp;
            this.openapp.open();
            // window.location = 'https://app.minitable.net/review/?sid=85a0dc31e266b4de1d1d9d6c77760531&type=profile';

            setTimeout(() => {
                this.show_accept_layer = true;
            }, 2000);
        },
        computed: {
            ...mapGetters({
                waitlist_info: 'waitlist/get_waitlist_info',
                store_info: 'waitlist/get_store_info',
                user_list: 'waitlist/get_user_list'
            }),
            ...mapState({
            })
        },
        methods: {
            close_download_layer() {
                this.show_download_layer = false;
            },
            render_share() {
                if (this.is_wexin) {
                    let link = window.location.href;
                    let img = 'https://d3puj4wh202ha4.cloudfront.net/static/minipass_purple.png';
                    this.wechat.share(this.invite_by + ' invites you to join the party', 'Manage your dine-in events with Minipass easily', link, img)
                }
            },
            ...mapActions({
                getWaitlistInfo: 'waitlist/getWaitlistInfo'
            }),
            ...mapMutations({}),
            open_layer() {
                if (this.user_list.length >= this.waitlist_info.people_num) {
                    return;
                }

                this.show_accept_layer = true;
                // this.doOpen();
            },
            do_open(_url) {
                // this.openapp.open();
                window.open(_url || "https://apps.apple.com/cn/app/minipass-minitable/id1626268909?l=en")
                // window.location = "https://apps.apple.com/cn/app/minitable-insight/id1553672570";
            },
            do_accept_download() {
                 gtag("event", "waitlist_share_layer_to_appstore", {
                    bid: this.id
                });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=party-invitation-popup-window&mt=8');
            },
            do_banner_open() {
                gtag("event", "waitlist_share_banner_to_appstore", {
                    bid: this.id
                });

                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=party-invitation-banner&mt=8')
            },
            doOpen() {
                // window.location = `minipass://invite/?id=${this.id}&business=waitlist&token=${this.token}&phone=${this.phone}`;
                window.location = `https://app.minitable.net/review/?sid=&type=invite&id=${this.id}&business=waitlist&token=${this.token}&phone=${this.phone}`;
            },
            doAccept() {
                this.doOpen();
                this.show_accept_layer = false;

                gtag("event", "waitlist_layer_accpet", {
                    bid: this.id
                });

                setTimeout(() => {
                    this.$nextTick(() => {
                        this.show_download_layer = true;
                    });
                }, 2000);
            },
            doCancel() {
                this.show_accept_layer = false;

                gtag("event", "waitlist_layer_cancel", {
                    bid: this.id
                });
            }
        }
    };
</script>

<style>
    .join_us {
        padding: 2rem 1rem 0;
        position: relative;
        /* background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 180% auto; */
    }
    .join_us_bg {
        width: 100%;
        height: 25rem;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 160% auto;
        z-index: -1;
    }
    .join_header {
        margin: 0 auto;
        width: 7.5rem;
        height: 1.25rem;
        background-image: url('../assets/images/minipass_white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 2rem;
    }
    .join_content{
        background-color: #fff;
        text-align: center;
        border-radius: .75rem;
        padding-top: 0.75rem;
        box-shadow: 0px 8px 5px rgba(230, 230, 230, 0.4);
    }
    .join_content h2, .join_content h3 {
        line-height: 1.75rem;
        font-weight: bolder;
    }
    .join_content h2, .join_content h4 {
        font-size: 1rem;
    }
    .join_content h3 {
        font-size: 1.25rem;
    }
    .join_banner {
        padding-top: 0.75rem;
    }
    .join_banner img {
        width: 50%;
        margin: 0 auto;
    }
    .color_line {
        margin-top: 0.75rem;
        height: 3px;
        background-image: url('../assets/images/line_colorful.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
    }
    .join_invite {
        margin-top: 1rem;
        padding: 0 .75rem 1rem;
    }
    .join_invite .invite {
        background-color: #f4f5f6;
        border-radius: 0.5rem;
        padding: 1rem 0;
        margin-bottom: 1rem;
    }
    .invite_title {
        text-align: left;
        padding: 0 1rem;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: bolder;
    }
    .invite_group {
        display: flex;
    }
    .invite_item {
        flex: 1;
        text-align: center;
    }
    .invite_item .img_bg {
        display: inline-block;
        width: 2.75rem;
        height: 2.75rem;
        border: .125rem solid #8423FE;
        border-radius: 50%;
        overflow: hidden;
    }
    .invite_item .img_bg img {
        width: 100%;
        height: 100%;
    }
    .invite_item .img_bg.more {
        background-image: url('../assets/images/dot_three.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60% auto;
    }
    .join_footer {
        margin-top: 1rem;
        width: 100%;
        height: 5rem;
        background-color: #FFF4AE;
        background-image: url('../assets/images/yellow_bg.png');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        border-radius: 0.75rem;
        overflow: hidden;
    }
    .footer_left {
        float: left;
        height: 3rem;
        padding: 1rem .625rem;
    }
    .footer_left span {
        display: inline-block;
    }
    .footer_left .footer_phone {
        width: 3rem;
        height: 3rem;
        background-image: url('../assets/images/minipass_phone.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
    }
    .footer_left .footer_text {
        width: 8.5rem;
        float: right;
        margin-left: 0.625rem;
        font-size: 0.75rem;
        font-family: Poppins-Bold;
        margin-top: 0.5rem;
    }
    .footer_right {
        float: right;
        margin-right: .625rem;
    }
    .down_btn {
        margin-top: 1.5rem;
        display: block;
        width: 4.75rem;
        height: 2rem;
        line-height: 2rem;
        background-color: #7427FF;
        background-image: url('../assets/images/apple_icon.png');
        background-position: .5rem center;
        background-repeat: no-repeat;
        background-size: auto 50%;
        border-radius: 1.5rem;
        color: #fff;
        padding-left: 1.875rem;
    }
</style>
