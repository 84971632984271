import fetchApi from '../../kits/newFetch';
import axiosApi from '../../kits/axiosApi';

// initial state
const state = {
    survey_info: {},
};

// getters
const getters = {
    get_survey_info(state) {
        return state.survey_info;
    },
};

// actions
const actions = {
    getSurveyInfo({ commit, rootState, rootGetters, dispatch }, id) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/survey/info?task_id=' + id, {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);
            if (res.data.code == 100000) {
                commit('SURVEY_INFO', res.data.data);
            }
        });
    },
    submitSurvey({ commit, rootState, rootGetters, dispatch }, params) {
        let aj_host = rootGetters.serverRoot;
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + '/weapp/survey/submit', params)
                .then((res) => {
                    let data = res.data.data;
                    resolve(data);
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
};

// mutations
const mutations = {
    SURVEY_INFO(state, _data) {
        state.survey_info = _data.info;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
