import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    prize_info: {},
    store_info: null
};

// getters
const getters = {
    get_prize_info(state) {
        return state.prize_info;
    },
    get_store_info(state) {
        return state.store_info;
    },
};

// actions
const actions = {
    getPrizeInfo({ commit, rootState, rootGetters, dispatch }, post_data) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/prize/info?oid=' + post_data.oid + '&sid=' + post_data.sid, {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);
            if (res.data.code == 100000) {
                commit('PRIZE_SET_INFO', res.data.data.info);
            }
        });
    },
};

// mutations
const mutations = {
    PRIZE_SET_INFO(state, _data) {
        state.store_info = _data.store_info;
        delete _data.store_info;
        state.prize_info = _data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
