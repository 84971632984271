<template>
    <div id="app_center" class="center_pc">
        <div class="landing_page"  ref="landingPage">
            <div class="landing_bg_pc"></div>
            <div class="minitable_icon"></div>
            <div class="landing_content">
                <div class="landing_inner" ref="landingInner">
                    <div class="info_img">
                        <span class="info_waitlist">Waitlist</span>
                        <span class="info_reserve">Reservation</span>
                        <span class="info_shape"></span>
                        <span class="info_bg"></span>
                    </div>
                    <div class="info_text">
                        <div class="text_content">
                            <div class="text_icon"></div>
                            <h3>
                                A free, fun and easy way
                                <br>
                                to manage your
                                <span class="ft_puper">dining</span>
                                <br>
                                experience
                            </h3>
                            <p>Invite friends to waitlist and reservation parties at your fingertips</p>
                        </div>
                        <div class="landing_button" ref="downloadBtn">
                            <div class="landing_button_content">
                                <span class="shadow_pc"></span>
                                <a class="store_btn" href="javascript:;" @click="do_click_download">
                                    <span class="download">Download on the</span>
                                    <span class="btn_text">App Store</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="landing_footer">
                <a class="" href="javascript:;">Powered by minitable</a>
            </div>
        </div>
    </div>
</template>

<style>
.landing_page {
    background-color: #fff;
    background-image: url('../assets/images/puper_bg.png');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.minitable_icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 5.75rem;
    height: 1.75rem;
    background-image: url('../assets/images/minitable_icon.png');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.info_img {
    padding: 1rem;
    position: relative;
}
.info_img .info_waitlist, .info_img .info_reserve {
    display: block;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    color: #fff;
    box-sizing: border-box;
    line-height: 3;
    font-size: .875rem;
    font-family: Cera-Pro;
}
.info_img .info_waitlist {
    padding-left: .875rem;
    left: 2.5rem;
    top: 5.5rem;
    width: 6.625rem;
    height: 2.1875rem;
    background-image: url('../assets/images/waitlist.png');
}
.info_img .info_reserve {
    padding-left: 2.25rem;
    padding-top: 1.25rem;
    right: 2.125rem;
    bottom: 1.5rem;
    width: 8.3125rem;
    height: 3.6875rem;
    background-image: url('../assets/images/reserve.png');
}
.info_img span.info_bg{
    display: block;
    width: 100%;
    height: 28rem;
    background-image: url('../assets/images/info.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
}
.info_text {
    margin-top: 1rem;
    text-align: center;
    padding: 1rem 2rem;
}
.text_icon {
    width: 100%;
    height: 1.25rem;
    background-image: url('../assets/images/minipass_purple.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto 100%;
    margin-bottom: 1rem;
}
.text_content h3 {
    font-size: 1.375rem;
    line-height: 2;
    font-weight: bold;
    font-family: Poppins-Bold;
}
.text_content p {
    margin-top: 1rem;
    line-height: 1.6;
    font-size: .875rem;
}
.landing_button {
    margin-top: 4.5rem;
    padding: .75rem 0;
    width: 100%;
    height: 3.125rem;
}
.store_btn {
    display: block;
    width: 100%;
    height: 3.125rem;
    line-height: 3.125rem;
    background-color: #7427FF;
    background-image: url('../assets/images/apple_icon.png');
    background-position: 5.5rem center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius: 2rem;
    color: #fff;
    padding-left: 2.875rem;
    box-sizing: border-box;
}
.download {
    display: none;
}
.btn_text {
    font-size: 1rem;
}
.landing_footer {
    margin-top: 1.5rem;
    padding: 1rem;
    text-align: center;
}
.landing_footer a {
    color: #1F1F39;
}
@media screen and (max-width:820px){
    .fixed_btn.landing_button .landing_button_content {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        padding: .75rem 3rem;
        /* max-width: 768px; */
        bottom: 0;
        left: 0;
        background-color: #fff;
    }
}
@media screen and (min-width:820px){
    #app_center.center_pc {
        max-width: none;
    }
    .minitable_icon {
        width: 92px;
        height: 28px;
        top: 21px;
        left: 19px;
    }
    .landing_page {
        position: fixed;
        width: 100%;
        height: 100%;
        /* background: none; */
        /* background-image: url('../assets/images/bitmap.png'); */
        background: linear-gradient(270deg, rgba(191, 190, 242, 0.6) 0%, rgba(234, 242, 255, 0.6) 100%);
        /* background-size: auto 100%;
        background-position: top right; */
    }
    .landing_bg_pc {
        position: absolute;
        width: 100%;
        height: 100%;
        /* opacity: 0.600214; */
        /* background: linear-gradient(270deg, rgba(191, 190, 242, 1) 0%, rgba(234, 242, 255, 1) 100%); */
        background-image: url('../assets/images/bitmap.png');
        background-size: auto 100%;
        background-position: top right;
        background-repeat: no-repeat;
        z-index: -1;
    }
    .landing_content {
        margin: 0 auto;
        display: flex;
        height: 100%;
        max-width: 852px;
        align-items: center;
    }
    .landing_inner {
        overflow: hidden;
        width: 100%;
        max-height: 430px;
    }
    .info_img {
        width: 420px;
        float: right;
        box-sizing: border-box;
        margin-top: 0px;
    }
    .info_img .info_waitlist {
        padding-left: 14px;
        left: 32px;
        top: unset;
        bottom: 40px;
        width: 122px;
        height: 40px;
        background-image: url('../assets/images/waitlist_pc.png');
        line-height: 2.5;
        font-size: 18px;
    }
    .info_img .info_reserve {
        padding-left: 11px;
        padding-top: 0;
        right: 30px;
        bottom: unset;
        top: 33px;
        width: 154px;
        height: 66px;
        background-image: url('../assets/images/reserve_pc.png');
        line-height: 2.5;
        font-size: 18px;
    }
    .info_img .info_shape {
        width: 509px;
        height: 460px;
        display: block;
        position: absolute;
        bottom: -98px;
        right: 25px;
        z-index: -1;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-image: url('../assets/images/shape_pc.png');
    }
    .info_img span.info_bg {
        width: 400px;
        height: 373px;
        background-image: url('../assets/images/info_pc.png');
    }
    .info_text {
        width: 394px;
        float: left;
        box-sizing: border-box;
        margin-top: 30px;
        padding-right: 0;
        text-align: left;
        padding-left: 51px;
    }
    .text_icon {
        height: 18px;
        background-position: left center;
        margin-bottom: 18px;
    }
    .text_content h3 {
        font-size: 28px;
        line-height: 1.56;
        font-family: 'Cera-Pro';
    }
    .text_content p {
        font-size: 14px;
        margin-top: 18px;
    }
    .landing_button {
        margin-top: 20px;
    }
    .landing_button_content {
        position: relative;
    }
    .landing_button_content .shadow_pc {
        display: block;
        width: 136px;
        height: 8px;
        position: absolute;
        left: 12px;
        bottom: 4px;
        border-radius: 5px;
        opacity: 0.7;
        z-index: -1;
        box-shadow: 0px 7px 14px rgba(6,16,88, 0.88);
    }
    .store_btn {
        width: 153px;
        height: 46px;
        background-size: auto 62%;
        background-position: 13px center;
        line-height: 16px;
        padding-top: 0px;
        padding-left: 48px;
    }
    .store_btn .download {
        display: block;
        font-size: 12px;
        line-height: 1.3;
        transform: scale(0.8);
        margin-left: -9px;
        padding-top: 4px;
    }
    .store_btn .btn_text {
        font-size: 16px;
    }
    .landing_footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        font-size: 14px;
        padding: 12px;
    }

    .not_fixed.landing_page {
        position: relative;
    }
    .not_fixed .landing_content {
        padding-top: 60px;
        position: relative;
        z-index: 3;
    }
    .not_fixed .landing_footer {
        position: static;
        margin-top: 20px;
    }
    .not_fixed .landing_bg_pc {
        z-index: 0;
    }
}
@media screen and (min-width:960px){
    .landing_content {
        max-width: 1024px;
    }
    .landing_inner {
        max-height: 487px;
    }
    .info_img {
        width: 492px;
        margin-top: 0px;
    }
    .info_img .info_waitlist {
        padding-left: 21px;
        left: 40px;
        bottom: 35px;
        width: 143px;
        height: 47px;
        font-size: 18px;
        line-height: 2.8;
    }
    .info_img .info_reserve {
        padding-left: 20px;
        padding-top: 0;
        right: 44px;
        top: 35px;
        width: 180px;
        height: 78px;
        font-size: 18px;
        line-height: 3;
    }
    .info_img .info_shape {
        width: 597px;
        height: 540px;
        bottom: -124px;
        right: 34px;
    }
    .info_img span.info_bg {
        width: 468px;
        height: 438px;
    }
    .info_text {
        width: 462px;
        margin-top: 30px;
        padding-right: 0;
        padding-left: 60px;
    }
    .text_icon {
        height: 20px;
        margin-bottom: 22px;
    }
    .text_content h3 {
        font-size: 33px;
        line-height: 1.56;
    }
    .text_content p {
        font-size: 14px;
        margin-top: 18px;
    }
    .landing_button {
        margin-top: 26px;
    }
    .landing_button_content .shadow_pc {
        width: 159px;
        height: 9px;
        left: 12px;
        bottom: 4px;
        box-shadow: 0px 10px 20px rgba(6,16,88, 0.88);
    }
    .store_btn {
        width: 180px;
        height: 54px;
        background-position: 29px center;
        line-height: 1.2;
        padding-top: 4px;
        padding-left: 70px;
    }
    .store_btn .download {
        transform: scale(0.9);
        margin-left: -3px;
        padding-top: 7px;
        line-height: 8px;
    }
    .store_btn .btn_text {
        font-size: 17px;
    }
    .landing_footer {
        font-size: 14px;
        padding: 14px;
    }
}
@media screen and (min-width:1280px){
    .landing_content {
        max-width: 1366px;
    }
    .landing_inner {
        max-height: 650px;
    }
    .info_img {
        width: 657px;
        margin-top: 0px;
    }
    .info_img .info_waitlist {
        padding-left: 28px;
        left: 20px;
        bottom: 5px;
        width: 191px;
        height: 63px;
        font-size: 24px;
        line-height: 2.8;
    }
    .info_img .info_reserve {
        padding-left: 27px;
        padding-top: 0;
        right: 20px;
        top: 10px;
        width: 241px;
        height: 104px;
        font-size: 24px;
        line-height: 3;
    }
    .info_img .info_shape {
        width: 796px;
        height: 720px;
        bottom: -170px;
        right: 60px;
    }
    .info_img span.info_bg {
        width: 625px;
        height: 584px;
    }
    .info_text {
        width: 616px;
        margin-top: 40px;
        padding-right: 0;
        padding-left: 80px;
    }
    .text_icon {
        height: 26px;
        margin-bottom: 30px;
    }
    .text_content h3 {
        font-size: 45px;
        line-height: 1.56;
    }
    .text_content p {
        font-size: 18px;
        margin-top: 26px;
    }
    .landing_button {
        margin-top: 1rem;
    }
    .landing_button_content {
        position: relative;
    }
    .landing_button_content .shadow_pc {
        width: 213px;
        height: 13px;
        left: 12px;
        bottom: 4px;
        border-radius: 5px;
        box-shadow: 0px 10px 20px rgba(6,16,88, 0.88);
    }
    .store_btn {
        width: 240px;
        height: 72px;
        background-position: 39px center;
        line-height: 1.2;
        padding-top: 4px;
        padding-left: 94px;
    }
    .store_btn .download {
        transform: none;
        margin-left: 0;
        padding-top: 5px;
        line-height: 1.8;
    }
    .store_btn .btn_text {
        font-size: 22px;
    }
    .landing_footer {
        font-size: 18px;
        padding: 18px;
    }
}
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'thanks',
        components: {
        },
        data() {
            return {
                sid: this.$route.query.sid || '',
                source: this.$route.query.source || '',
                id: this.$route.query.id || ''
            };
        },
        async created() {
            // document.title = 'Minipass - Book, Manage and Share Your Dining Experiences';
        },
        watch: {},
        mounted() {
            const wWidth = window.innerWidth;
            // console.log('wWidth', wWidth);
            let downloadBtn = this.$refs.downloadBtn;
            if (wWidth < 1280) {
                downloadBtn.className = 'landing_button fixed_btn';
                window.addEventListener('scroll', this.scrollOverHeader);
            } else {
                downloadBtn.className = 'landing_button';
                let landingPage = this.$refs.landingPage;
                let landingInner = this.$refs.landingInner;
                if (landingInner.offsetHeight > landingPage.offsetHeight - 100) {
                    landingPage.className = 'landing_page not_fixed';
                }
            }

            if (this.source == 'sms') {
                gtag("event", "landing_page_pv_sms", {
                    bid: this.id
                });
            } else {
                gtag("event", "landing_page_pv", {
                    bid: this.id
                });
            }
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({}),
            ...mapMutations({}),
            scrollOverHeader() {
                // let menuHeaderHeight = this.$refs.menuHeader.offsetHeight;
                let downloadBtn = this.$refs.downloadBtn;
                const  diff = downloadBtn.offsetTop - window.innerHeight;
                // console.log('downloadBtn', downloadBtn)
                if ((diff > 0 && window.scrollY > diff) || diff < 0) {
                    downloadBtn.className = 'landing_button';
                } else {
                    downloadBtn.className = 'landing_button fixed_btn';
                }
            },
            do_open(_url) {
                window.open(_url || "https://apps.apple.com/cn/app/minipass-minitable/id1626268909?l=en")
            },
            do_click_download() {
                if (this.source == 'waitlist_waiting') {
                    gtag("event", "waitlist_waiting_banner_download", {
                        bid: this.id
                    });
                    
                    this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=H5banner-landingpage&mt=8');
                } else if (this.source == 'reserve_waiting') {
                    gtag("event", "reserve_waiting_banner_download", {
                        bid: this.id
                    });

                    this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=H5banner-landingpage&mt=8');
                } else if (this.source == 'sms') {
                    gtag("event", "sms_banner_download", {
                        bid: this.id
                    });

                    this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=universal_html_download&mt=8');
                } else {
                    gtag("event", "landing_page_default_download", {
                        bid: this.id
                    });

                    this.do_open('https://apps.apple.com/cn/app/minipass-minitable/id1626268909?l=en');
                }
            }
        }
    };
</script>
