import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    draw_info: {},
    prize_list: [],
    prize_info: {},
    store_info: {},
    err_msg: '',
    has_joined: window.sessionStorage.getItem('has_joined') ? true : false
};

// getters
const getters = {
    get_draw_info(state) {
        return state.draw_info;
    },
    get_prize_info(state) {
        return state.prize_info;
    },
    get_store_info(state) {
        return state.store_info;
    },
    get_prize_list(state) {
        return state.prize_list;
    },
    get_err_msg(state) {
        return state.err_msg;
    },
    get_has_joined(state) {
        return state.has_joined;
    }
};

// actions
const actions = {
    getDrawInfo({ commit, rootState, rootGetters, dispatch }, draw_id) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/draw/info?draw_id=' + draw_id, {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);
            if (res.data.code == 100000) {
                commit('DRAW_SET_INFO', res.data.data);
            }
        });
    },
    sendCode({ commit, rootState, rootGetters, dispatch }, post_data) {
        let aj_host = rootGetters.serverRoot;
        commit('DRAW_SET_ERROR', '');

        fetch(aj_host + '/weapp/sms/send?sid=' + post_data.sid + '&mobile=' + encodeURIComponent(post_data.phone_num), {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);

            if (res.data.code != 100000) {
                commit('DRAW_SET_ERROR', res.data.msg);
            }
        });
    },
    verifyCode({ commit, rootState, rootGetters, dispatch }, post_data) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/sms/verify?sid=' + post_data.sid + '&mobile=' + encodeURIComponent(post_data.phone_num) + '&code=' + post_data.code + '&draw_id=' + post_data.draw_id + '&event_name=' + encodeURIComponent(post_data.event_name), {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);

            if (res.data.code != 100000) {
                commit('DRAW_SET_ERROR', res.data.msg);
            } else {
                // 调用参加活动的接口
                commit('DRAW_SET_JOINED', {});
            }
        });
    },
};

// mutations
const mutations = {
    DRAW_SET_INFO(state, _data) {
        // _data.draw_info.event_status = 2;

        state.draw_info = _data.draw_info;
        state.prize_info = _data.prize_info;
        state.store_info = _data.store_info;
        state.prize_list = _data.prize_list;
    },
    DRAW_SET_ERROR(state, err_msg) {
        state.err_msg = err_msg;
        state.has_joined = false;
    },
    DRAW_SET_JOINED(state) {
        state.err_msg = '';
        state.has_joined = true;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
