<template>
    <div id="app_center">
        <div class="join_us">
            <div class="join_us_bg"></div>
            <div class="join_header"></div>
            <div class="coupon_content" style="margin-bottom: 6rem">
                <div class="store_content" style="position: relative; padding: 30px 20px;">
                    <div class="store_logo">
                        <img style="width: 100%;border-radius: 50%;" :src="store_info.slogo" />
                    </div>
                    <div class="store_desc">
                        <p class="title">{{ store_info && store_info.sname || ''}}</p>
                        <p class="subtitle">{{ store_info && store_info.street_address + ',' + store_info.locality + ',' + store_info.postal_code }}</p>
                    </div>
                </div>
                <div class="coupon_desc">
                    <p class="coupon_title">{{ coupon_info.name }}</p>
                    <p class="coupon_subtitle">{{ coupon_info.describe }}</p>
                </div>
                <div class="submit_button" @click="do_get" v-if="coupon_info.status == 1">
                    <span v-if="coupon_info.point == 0"> Redeem in Minipass for Free </span>
                    <span v-if="coupon_info.point > 0"> Redeem in Minipass for {{ coupon_info.point }} pt </span>
                </div>
                <div class="submit_button" style="opacity: 0.5;" v-else>
                    <span> Not available </span>
                </div>
                <div class="coupon_tip" v-if="coupon_info.redeem_etime != '' || coupon_info.use_etime != ''">
                    Redemption available till {{ coupon_info.redeem_etime || coupon_info.use_etime }}
                </div>
            </div>
            <div class="profile_down">
                <div class="footer_left">
                    <span class="footer_phone"></span>
                    <span class="footer_text ft_puper">
                        Free, fun and easy
                        <br/>
                        at Minipass
                    </span>
                </div>
                <div class="footer_right purple_icon">
                    <a class="down_btn no_icon" @click="fixed_layer_to_store" href="javscript:;">Download</a>
                    <a class="down_btn" href="javascript:;" @click="fixed_layer_to_app">Open app</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import OpenApp from 'web-open-app';
    import Swal from 'sweetalert2';
    import moment from 'moment';

    export default {
        name: 'draw_info',
        components: {
        },
        data() {
            return {
                coupon_token: decodeURIComponent(this.$route.params.id) || '',
                show_verfiy_layer: false,
                is_wexin: navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1,
                moment,
                phone_num: '',
                code: '',
                sending_time: 0,
                timer: null,
                is_loading: false,
                draw_layer_show: false
            };
        },
        created() {
            if (this.coupon_token) {
                this.getCouponInfo(this.coupon_token);
            }

            // gtag("event", "draw_share_pv", {
            //     bid: this.draw_id
            // });
        },
        watch: {
        },
        mounted() {},
        computed: {
            ...mapGetters({
                coupon_info: 'coupon/get_coupon_info',
                store_info: 'coupon/get_store_info'
            }),
            ...mapState({
            }),
        },
        methods: {
            getCouponInfo(token) {

            },

            doLayerDownload() {
                // gtag("event", "draw_join_layer_to_store", {
                //     bid: this.draw_id
                // });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=drawH5_paid_popup_download&mt=8');
                this.draw_layer_show = false;
            },
            doLayerDownloadCancel() {
                this.draw_layer_show = false;
            },
            ...mapActions({
                getCouponInfo: 'coupon/getCouponInfo',
            }),
            fixed_layer_to_store() {
                // gtag("event", "draw_fixed_layer_to_store", {
                //     bid: this.draw_id
                // });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=draw-H5-banner-download&mt=8');
            },
            fixed_layer_to_app() {
                // gtag("event", "draw_fixed_layer_to_app", {
                //     bid: this.draw_id
                // });
                
                this.do_open_app();
            },
            do_open(_url) {
                window.open(_url || "https://apps.apple.com/cn/app/minipass-minitable/id1626268909?l=en")
            },
            do_get() {
                this.do_open_app();
            },
            do_open_app() {
                window.location = `https://app.minitable.net/review/?id=` + this.coupon_token + '&type=coupon';

                setTimeout(() => {
                    this.$nextTick(() => {
                        Swal.fire({
                            title: 'Not installed yet?',
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'Download',
                            denyButtonText: `Cancel`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // gtag("event", "draw_popup_to_store", {
                                //     bid: this.draw_id
                                // });

                                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=draw-H5-popup-download&mt=8');
                            }
                        })
                    });
                }, 2000);
            },
        }
    };
</script>

<style>
    .join_us {
        padding: 2rem 1rem 0;
        position: relative;
        /* background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 180% auto; */
    }
    .join_us_bg {
        width: 100%;
        height: 25rem;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 160% auto;
        z-index: -1;
    }
    .join_header {
        margin: 0 auto;
        width: 7.5rem;
        height: 1.25rem;
        background-image: url('../assets/images/minipass_white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 2rem;
    }
    .coupon_content{
        height: 477px;
        border-radius: 17.9712px;
        opacity: 1;
        background-image: linear-gradient(-45deg, rgba(206, 236, 254, 1) 0%, rgba(240, 226, 255, 1) 100%);
    }
    .coupon_content .store_content .store_logo {
        /* box-shadow: 0px 1px 2px 1px rgba(191,191,191, 0.5); */
        opacity: 1;
        /* background-color: rgba(255,255,255, 1); */
        width: 75px;
        height: 75px;
        display: inline-block;
    }
    .coupon_content .store_content .store_desc {
        display: inline-block;
        width: 190px;
        padding-left: 30px;
        height: 75px;
        vertical-align: top;
    }

    .store_desc .title {
        opacity: 1;
        color: rgba(31,32,34,1);
        font-family: "PingFangSC-Medium";
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0.25px;
        text-align: left;
    }

    .store_desc .subtitle {
        opacity: 1;
        color: rgba(34,35,37,1);
        font-family: "PingFangSC-Regular";
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.18px;
        text-align: left;
        margin-top: 12px;
    }

    .coupon_content .coupon_desc {
        box-shadow: 0px 8px 45px -10px rgba(184,184,210, 0.5);
        opacity: 1;
        background-color: rgba(255,255,255, 1);
        min-height: 172px;
        margin: 0 20px;
    }

    .coupon_desc .coupon_title {
        opacity: 1;
        color: rgba(34,35,37,1);
        font-family: "Poppins-SemiBold";
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0px;
        text-align: center;
        padding-top: 23px;
    }

    .coupon_desc .coupon_subtitle {
        opacity: 1;
        color: rgba(34,35,37,1);
        font-family: "PingFangSC-Regular";
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.22px;
        text-align: center;
        padding: 17px 22px;
    }

    .coupon_content .submit_button {
        height: 48px;
        width: 279px;
        margin: 40px auto 14px;
        border-radius: 17.5px;
        box-shadow: 0px 8px 16px rgba(82,48,166, 0.3);
        opacity: 1;
        background-image: linear-gradient(90deg, rgba(116, 39, 255, 1) 0%, rgba(69, 50, 250, 1) 100%, rgba(67, 50, 250, 1) 100%);
    }

    .coupon_content .submit_button span {
        opacity: 1;
        color: rgba(255,255,255,1);
        font-family: "Poppins-Medium";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0px;
        text-align: center;
        line-height: 48px;
        display: inline-block;
        width: 100%;
    }

    .coupon_content .coupon_tip {
        opacity: 1;
        color: rgba(117,40,255,1);
        font-family: "Poppins-Regular";
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.18px;
        text-align: center;
        line-height: 13px;
    }




    .profile_main {
        padding: 0 .75rem .75rem;
    }
    .profile_main img {
        border-radius: .75rem;
        display: block;
        width: 100%;
    }
    .profile_main h2 {
        margin: .75rem 0;
        line-height: 1.75rem;
        text-align: left;
    }
    .profile_main p {
        padding-left: 1.25rem;
        text-align: left;
        color: #333;
        background-repeat: no-repeat;
        background-size: 1rem auto;
        background-position: left center;
        line-height: 1.5rem;
    }
    .profile_main .profile_address {
        background-image: url('../assets/images/address_icon.png');
    }
    .profile_main .profile_tel {
        background-image: url('../assets/images/phone_icon.png');
    }
    .profile_card {
        margin-bottom: 1.75rem;
    }
    .profile_card h2 {
        font-size: 1rem;
        color: #000;
    }
    .profile_info {
        text-align: left;
        color: #333;
        line-height: 1.5;
    }
    .share_card .profile_info {
        color: #9098a3;
    }
    .share_item {
        text-align: left;
        padding-left: 2rem;
        padding-bottom: 1.5rem;
        margin-bottom: .5rem;
        position: relative;
        background: url('../assets/images/line_icon.png') no-repeat .5rem bottom;
        background-size: .3125rem auto;
    }
    .share_info .share_item:last-child {
        background: none;
        padding-bottom: 0;
    }
    .share_item_img {
        width: 1.3125rem;
        height: 1.3125rem;
        position: absolute;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .share_item_img.time_icon {
        background-image: url('../assets/images/time_icon.png');
    }
    .share_item_img.notify_icon {
        background-image: url('../assets/images/notify_icon.png');
        background-size: auto 100%;
    }
    .share_item_img.flower_icon {
        background-image: url('../assets/images/flower_icon.png');
    }
    .share_item_txt {
        margin-top: .25rem;
        color: #8F98A3;
    }
    .profile_btn a.no_start {
        background-color: #ba93fe;
    }
    .profile_btn_txt {
        padding-top: 10px;
    }
    .profile_btn {
        margin-top: 1.75rem;
    }
    .profile_btn a {
        display: flex;;
        height: 2.5rem;
        line-height: 2.5rem;
        color: #fff;
        background-color: #7427ff;
        text-align: center;
        border-radius: 1.25rem;
        justify-content: center;
        font-size: .875rem;
        font-family: 'Poppins-SemiBold';
    }
    .profile_btn a i {
        display: inline-block;
        width: 2rem;
        height: 2.5rem;
        background-repeat: no-repeat;
        background-size: 1rem auto;
        background-position: left center;
    }
    .profile_btn a i.link_reseve {
        background-image: url('../assets/images/reseve_icon.png');
    }
    .profile_btn a i.link_wait {
        background-image: url('../assets/images/wait_icon.png');
    }
    .profile_link a {
        color: #9ca4ae;
    }
    .profile_down {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        /* max-width: 768px; */
        bottom: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0px 8px 14px rgba(0,0,0, 0.15);
    }
    .profile_down .footer_left .footer_phone {
        border-radius: 10px;
        box-shadow: 0px 7px 14px rgba(0,0,0, 0.15);
    }
    .profile_down .footer_right{
        width: 16rem;
        display: flex;
        justify-content: space-between;
        transform: scale(.5);
        float: none;
        position: absolute;
        right: -3.5rem;
        top: 1rem;
    }
    .profile_down .footer_right.purple_icon{
        width: 18rem;
        right: -4.25rem;
    }
    .profile_down .down_btn {
        background: #7427FF;
        border: 2px solid #7427FF;
        padding: 0 .25rem;
        text-align: center;
        width: 7.5rem;
        height: 3.25rem;
        line-height: 3.25rem;
        font-size: 1.1875rem;
        box-sizing: border-box;
        margin-top: 0;
        font-family: 'Poppins-SemiBold';
    }
    .profile_down .down_btn.no_icon {
        background: #fff;
        color: #7427FF;
    }
    .profile_down .purple_icon .down_btn.no_icon {
        width: 10rem;
        background-image: url('../assets/images/apple_icon_purple.png');
        background-position: .5rem center;
        background-repeat: no-repeat;
        background-size: auto 50%;
        padding-left: 1.75rem;
    }
    .join_footer {
        margin-top: 1rem;
        width: 100%;
        height: 5rem;
        background-color: #FFF4AE;
        background-image: url('../assets/images/yellow_bg.png');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        border-radius: 0.75rem;
        overflow: hidden;
    }
    .footer_left {
        float: left;
        height: 3rem;
        padding: 1rem .625rem;
    }
    .footer_left span {
        display: inline-block;
    }
    .footer_left .footer_phone {
        width: 3rem;
        height: 3rem;
        background-image: url('../assets/images/minipass_phone.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
    }
    .footer_left .footer_text {
        width: 8.5rem;
        float: right;
        margin-left: 0.625rem;
        font-size: 0.75rem;
        font-family: Poppins-Bold;
        margin-top: 0.5rem;
    }
    .profile_down .footer_left .footer_text {
        font-family: 'Poppins-SemiBold';
    }
    .footer_right {
        float: right;
        margin-right: .625rem;
    }
    .down_btn {
        margin-top: 1.5rem;
        display: block;
        width: 4.75rem;
        height: 2rem;
        line-height: 2rem;
        background-color: #7427FF;
        background-image: url('../assets/images/apple_icon.png');
        background-position: .5rem center;
        background-repeat: no-repeat;
        background-size: auto 50%;
        border-radius: 1.5rem;
        color: #fff;
        padding-left: 1.875rem;
    }
    .share_input .input_item {
        padding: 0 .5rem .75rem;
    }
    .share_input {
        padding-bottom: .5rem;
    }
    .share_input input {
        display: block;
        width: 100%;
        border: 0;
        border-bottom: 1px solid #E2E7EE;
        line-height: 1.375rem;
        display: inline-block;
    }
    .share_input .input_code {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
    }
    .share_input .input_code input {
        width: 6.625rem;
    }
    .share_input .input_code a {
        display: block;
        width: 5.625rem;
        height: 1.75rem;
        line-height: 1.75rem;
        box-sizing: border-box;
        border: 1px solid #7427FF;
        border-radius: .875rem;
        color: #7427FF;
    }
    .share_input_error {
        color: red;
        text-align: left;
        padding: .25rem .5rem 0;
    }
    .share_img {
        min-height: 330px;
    }
</style>
