<template>
    <div id="app_center_prize">
        <div class="win_prize">
            <div class="prize_header"></div>
            <div class="prize_top">
                <div class="prize_name">{{ prize_info && prize_info.event_name }}</div>
                <div class="prize_congrat">Congrats on your wins</div>
                <ul class="prize_time">
                    <li>
                        <span>Order Placed</span>
                        <!-- <span>{{ prize_info && prize_info.order_time }}</span> -->
                        <span>{{ prize_info && prize_info.create_time }}</span>
                    </li>
                    <li>
                        <span>Order Number</span>
                        <span>{{ prize_info && prize_info.oid }}</span>
                    </li>
                </ul>
            </div>
            <div class="prize_content" v-if="prize_info">
                <h3>Summary</h3>
                <ul class="prize_time">
                    <li>
                        <span>Subtotal</span>
                        <span>${{ prize_info.price ? (prize_info.price * 1).toFixed(2) : '0.00' }}</span>
                    </li>
                    <li v-if="prize_info.tax && prize_info.tax > 0">
                        <span>Tax</span>
                        <span>${{ prize_info.tax ? (prize_info.tax * 1).toFixed(2) : '0.00' }}</span>
                    </li>
                    <li v-if="prize_info.service_fee_value && prize_info.service_fee_value > 0">
                        <span>Service fee</span>
                        <span>${{ prize_info.service_fee_value ? (prize_info.service_fee_value * 1).toFixed(2) : '0.00' }}</span>
                    </li>
                </ul>
                <ul class="prize_time">
                    <li>
                        <span>Total</span>
                        <span>${{ prize_info.total? (prize_info.total * 1).toFixed(2) : '0.00' }}</span>
                    </li>
                    <li v-if="prize_info.refund_value">
                        <span style="text-align: left;">
                            Refund
                            <br>
                            <span class="f_refund">on {{ prize_info.refund_create_time }}</span>
                        </span>
                        <span>${{ (prize_info.refund_value * 1).toFixed(2) }}</span>
                    </li>
                </ul>
                <ul class="prize_time" v-if="prize_info.refund_value">
                    <li>
                        <span>Net Payment</span>
                        <span>${{ netPayment }}</span>
                    </li>
                </ul>
            </div>
            <div class="prize_footer">
                <a class="" href="http://www.minitable.net/">Powered by <span class="fc_orange">minitable</span></a>
            </div>
        </div>
    </div>
</template>

<style>
#app_center_prize {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    font-family: Helvetica;
}
.win_prize {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 32px;
    background-color: #FCFBFF;
    min-height: 100vh;
    box-sizing: border-box;
}
.prize_header {
    margin: 0 auto;
    width: 170px;
    height: 28px;
    background-image: url(../assets/images/minipass_purple.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 42px;
}
.prize_top {
    font-size: 16px;
}
.prize_name {
    color: #7427FF;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    margin-bottom: 16px;
}
.prize_congrat {
    color: #8B8B8B;
    text-align: center;
    margin-bottom: 20px;
}
.prize_time li {
    display: flex;
    justify-content: space-between;
    line-height: 34px;
}
.prize_content {
    margin-top: 20px;
    font-size: 14px;
    background-color: #fff;
    text-align: center;
    border-radius: 29px;
    padding: 40px 32px 20px;
    box-shadow: 1px 4px 6px 3px rgba(215, 211, 211, 0.5);
    /* padding-bottom: 1rem; */
}
.prize_content h3 {
    font-size: 16px;
    font-weight: 700;
}
.prize_content ul {
    padding: 12px 0;
    border-bottom: 1px solid #DBDBDB;
}
.prize_content ul:last-child {
    border-bottom: none;
}
.prize_content .prize_time li {
    line-height: 20px;
    padding: 6px 0;
}
.f_refund {
    font-size: 12px;
    color: #8B8B8B;
}
.prize_footer {
    margin-top: 10px;
    padding: 20px 0;
    text-align: center;
}
.prize_footer a {
    color: #000;
    font-size: 12px;
}
.prize_footer .fc_orange {
    color: #FD8467;
}
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'prize',
        components: {
        },
        data() {
            return {
                sid: this.$route.params.sid || '',
                oid: this.$route.params.oid || '',
            };
        },
        async created() {
            this.getPrizeInfo({
                sid: this.sid,
                oid: this.oid
            });
            // document.title = 'Minipass - Book, Manage and Share Your Dining Experiences';
        },
        watch: {},
        mounted() {
            
        },
        computed: {
            ...mapGetters({
                prize_info: 'prize/get_prize_info',
                store_info: 'prize/get_store_info',
            }),
            ...mapState({
            }),
            netPayment() {
                let _price = (this.prize_info?.total - this.prize_info?.refund_value).toFixed(2);
                return _price < 0 ? '0.00' : _price;
            },
        },
        methods: {
            ...mapActions({
                getPrizeInfo: 'prize/getPrizeInfo'
            }),
            ...mapMutations({}),
        }
    };
</script>
