import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    store_info: null,
};

// getters
const getters = {
    get_store_info(state) {
        return state.store_info;
    },
};

// actions
const actions = {
    getStoreProfileInfo({ commit, rootState, rootGetters, dispatch }, sid) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/store/info?sid=' + sid, {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);
            if (res.data.code == 100000) {
                commit('STORE_SET_INFO', res.data.data);
            }
        });
    }
};

// mutations
const mutations = {
    STORE_SET_INFO(state, _data) {
        state.store_info = _data.info;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
