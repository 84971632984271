<template>
    <div id="app_center">
        <div class="join_us">
            <div class="join_us_bg"></div>
            <div class="join_header"></div>
            <div class="join_content">
                <div class="profile_main share_main">
                    <div class="share_video">
                        <template v-if="content_info && content_info.type == 'video'">
                            <d-player style="width:295px; height: 440px; margin: 0 auto;" ref="player" :options="options"></d-player>
                        </template>
                        <template v-else-if="content_info && content_info.type == 'image'">
                            <swiper :options="swiperOption" ref="mySwiper" @slideNextTransitionEnd="swiperTouchNext" @slidePrevTransitionEnd="swiperTouchPrev">
                                <swiper-slide v-bind:key="index" v-for="(item,index) in content_info.images_list">
                                    <img :src="item" />
                                </swiper-slide>
                                <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                            </swiper>
                        </template>
                    </div>
                    <template v-if="content_info && content_info.type == 'image'">
                        <div class="banner_num">{{currentPage}}/{{content_info.images_list.length}}</div>
                    </template>
                    <div :class="content_info && content_info.type == 'image' ? 'share_person person_img' : 'share_person'">
                        <div class="share_name">
                            <img :src="content_info && content_info.photo_url">
                            <span>{{content_info && content_info.customer_name || ''}}</span>
                        </div>
                        <p class="share_text">{{content_info && content_info.content || ''}}</p>
                        <div class="profile_btn">
                            <a class="" href="javascript:;" @click="go_to_app">
                                <span>View in Minipass</span>
                            </a>
                        </div>
                    </div>
                    <div class="profile_link">
                        <a href="javascript:;">
                            Explore more content with Minipass
                        </a>
                    </div>
                </div>
            </div>
            <div class="join_footer" style="margin-top: 2.5rem; margin-bottom: 7rem">
                <div class="footer_left">
                    <span class="footer_phone"></span>
                    <span class="footer_text ft_puper">
                        Get more information with Minipass
                    </span>
                </div>
                <div class="footer_right">
                    <a class="down_btn" @click="banner_to_store" href="javascript:;">Download</a>
                </div>
            </div>
            <div class="profile_down">
                <div class="footer_left">
                    <span class="footer_phone"></span>
                    <span class="footer_text ft_puper">
                        Free, fun, and easy at Minipass
                    </span>
                </div>
                <div class="footer_right">
                    <a class="down_btn no_icon" @click="fixed_layer_to_store" href="javscript:;">Download</a>
                    <a class="down_btn" href="javascript:;" @click="fixed_layer_to_app">Open app</a>
                </div>
            </div>
        </div>
        <div class="pop" :style="{display: show_download_layer ? 'block' : 'none'}">
            <div class="pop_main invitation" style="top: 16rem;">
                <div class="invite_main">
                    <h2 class="fy_bold" style="font-size: 1rem; text-align: left;">Get more information, please download Minipass at App Store.</h2>
                    <a class="invite_btn puper_btn" href="javascript:;" @click="do_accept_download">Download</a>
                    <a class="invite_btn" href="javascript:;" @click="close_download_layer">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import OpenApp from 'web-open-app';
    import Swal from 'sweetalert2';
    import no_image from '../assets/images/no_image.png';
    import VueDPlayer from 'vue-dplayer';
    import 'vue-dplayer/dist/vue-dplayer.css'
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.css";

    export default {
        name: 'Content',
        components: {
            'd-player': VueDPlayer,
            swiper,
            swiperSlide
        },
        data() {
            return {
                id: this.$route.params.id || '',
                no_image,
                is_wexin: navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1,
                show_download_layer: false,
                options: {
                    video: {
                        url: 'https://d3puj4wh202ha4.cloudfront.net/minipass/videos/DNPI1u9Szbg.mp4'
                    }
                },
                swiperOption: {
                    loop: true,
                    autoplay: {
                        delay: 3000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    // 显示分页
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true //允许分页点击跳转
                    },
                },
                currentPage: 1,
            };
        },
        async created() {
            if (this.id) {
                this.getContentInfo(this.id);
            }
        },
        watch: {
            content_info(){
                if (this.content_info) {
                    document.title = 'Minipass · ' + this.content_info.title_content;
                    if (this.content_info.type == 'video') {
                        this.options.video.url = this.content_info.video_url;
                        console.log('url', this.options.video.url);
                    }
                }
            }
        },
        mounted() {
            gtag("event", "discover_h5_pv", {
                bid: this.id
            });
        },
        computed: {
            ...mapGetters({
                content_info: 'content/get_content_info',
            }),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions({
                getContentInfo: 'content/getContentInfo'
            }),
            ...mapMutations({}),
            swiperTouchNext() {
                this.currentPage = this.currentPage + 1;
                if (this.currentPage > this.content_info.images_list.length) {
                    this.currentPage = 1;
                }
            },
            swiperTouchPrev() {
                this.currentPage = this.currentPage - 1;
                if (this.currentPage <= 0) {
                    this.currentPage = this.content_info.images_list.length;
                }
            },
            banner_to_store() {
                gtag("event", "discover_banner_to_store", {
                    bid: this.id
                });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=discoverH5_banner&mt=8');
            },
            fixed_layer_to_store() {
                gtag("event", "discover_fixed_layer_to_store", {
                    bid: this.id
                });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=discoverH5_download_button&mt=8');
            },
            fixed_layer_to_app() {
                gtag("event", "discover_fixed_layer_to_app", {
                    bid: this.id
                });
                
                this.do_open_app();
            },
            do_open(_url) {
                window.open(_url || "https://apps.apple.com/cn/app/minipass-minitable/id1626268909?l=en")
            },
            do_open_app() {
                window.location = `https://app.minitable.net/review/?pid=` + this.id + '&type=discovery';

                setTimeout(() => {
                    this.$nextTick(() => {
                        Swal.fire({
                            title: 'Not installed yet?',
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'Download',
                            denyButtonText: `Cancel`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                gtag("event", "discover_popup_to_store", {
                                    bid: this.sid
                                });

                                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=discoverH5_pop-up_download&mt=8');
                            }
                        })
                    });
                }, 2000);
            },
            go_to_app() {
                gtag("event", "discover_pass_button_click", {
                    bid: this.id
                });

                window.location = `https://app.minitable.net/review/?pid=` + this.id + '&type=discovery';

                setTimeout(() => {
                    this.$nextTick(() => {
                        this.show_download_layer = true;
                    });
                }, 2000);
            },
            close_download_layer() {
                this.show_download_layer = false;
            },
            do_accept_download() {
                gtag("event", "discover_layer_to_store", {
                    bid: this.id
                });

                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=discoverH5_pass_download&mt=8');
            },
        }
    };
</script>

<style>
    .join_us {
        padding: 2rem 1rem 0;
        position: relative;
        /* background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 180% auto; */
    }
    .join_us_bg {
        width: 100%;
        height: 25rem;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 160% auto;
        z-index: -1;
    }
    .join_header {
        margin: 0 auto;
        width: 7.5rem;
        height: 1.25rem;
        background-image: url('../assets/images/minipass_white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 2rem;
    }
    .join_content{
        background-color: #fff;
        text-align: center;
        border-radius: .75rem;
        padding-top: 0.75rem;
        box-shadow: 0px 8px 5px rgba(230, 230, 230, 0.4);
        /* padding-bottom: 1rem; */
    }
    .profile_main {
        padding: 0 .75rem .75rem;
    }
    .profile_main img {
        border-radius: .75rem;
        display: block;
        width: 100%;
    }
    .profile_main p {
        padding-left: 1.25rem;
        text-align: left;
        color: #333;
        background-repeat: no-repeat;
        background-size: 1rem auto;
        background-position: left center;
        line-height: 1.5rem;
    }
    .share_main {
        position: relative;
    }
    .share_video {
        margin-bottom: 1rem;
        min-height: 8rem;
    }
    .share_video video {
        width: 100%;
        border-radius: .625rem;
    }
    .share_person {
        position: absolute;
        left: 0;
        bottom: 6rem;
        width: 100%;
        padding: 0 2.5rem;
        box-sizing: border-box;
        color: #fff;
        z-index: 2;
    }
    .share_person.person_img {
        padding: 0 2rem;
        bottom: 3.875rem;
    }
    .share_name {
        display: flex;
        height: 2.5rem;
        line-height: 2.5rem;
        font-family: 'Poppins-SemiBold';
    }
    .share_name img {
        width: 2.5rem;
        margin-right: .75rem;
        border-radius: 50%;
    }
    .share_text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .share_main .profile_btn {
        margin-top: .75rem;
    }
    .share_main.profile_main p {
        padding-left: 0;
        color: #fff;
    }
    .profile_btn {
        margin-top: 1.75rem;
    }
    .profile_btn a {
        display: flex;;
        height: 2.5rem;
        line-height: 2.5rem;
        color: #fff;
        background-color: #7427ff;
        text-align: center;
        border-radius: 1.25rem;
        justify-content: center;
        font-size: .875rem;
        font-family: 'Poppins-SemiBold';
    }
    .share_main .profile_btn a {
        width: 8.375rem;
        margin: 0 auto;
        font-size: 0.75rem;
        height: 1.875rem;
        line-height: 1.875rem;
        font-family: 'Poppins';
    }
    .profile_link a {
        color: #9ca4ae;
    }
    .profile_down {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        /* max-width: 768px; */
        bottom: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0px 8px 14px rgba(0,0,0, 0.15);
        z-index: 10;
    }
    .profile_down .footer_left .footer_phone {
        border-radius: 10px;
        box-shadow: 0px 7px 14px rgba(0,0,0, 0.15);
    }
    .profile_down .footer_right{
        width: 16rem;
        display: flex;
        justify-content: space-between;
        transform: scale(.5);
        float: none;
        position: absolute;
        right: -3.5rem;
        top: 1rem;
    }
    .profile_down .down_btn {
        background: #7427FF;
        border: 2px solid #7427FF;
        padding: 0 .25rem;
        text-align: center;
        width: 7.5rem;
        height: 3.25rem;
        line-height: 3.25rem;
        font-size: 1.1875rem;
        box-sizing: border-box;
        margin-top: 0;
        font-family: 'Poppins-SemiBold';
    }
    .profile_down .down_btn.no_icon {
        background: #fff;
        color: #7427FF;
    }
    .join_footer {
        margin-top: 1rem;
        width: 100%;
        height: 5rem;
        background-color: #FFF4AE;
        background-image: url('../assets/images/yellow_bg.png');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        border-radius: 0.75rem;
        overflow: hidden;
    }
    .footer_left {
        float: left;
        height: 3rem;
        padding: 1rem .625rem;
    }
    .footer_left span {
        display: inline-block;
    }
    .footer_left .footer_phone {
        width: 3rem;
        height: 3rem;
        background-image: url('../assets/images/minipass_phone.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
    }
    .footer_left .footer_text {
        width: 8.5rem;
        float: right;
        margin-left: 0.625rem;
        font-size: 0.75rem;
        font-family: Poppins-SemiBold;
        margin-top: 0.5rem;
    }
    .profile_down .footer_left .footer_text {
        font-family: 'Poppins-SemiBold';
    }
    .footer_right {
        float: right;
        margin-right: .625rem;
    }
    .down_btn {
        margin-top: 1.5rem;
        display: block;
        width: 4.75rem;
        height: 2rem;
        line-height: 2rem;
        background-color: #7427FF;
        background-image: url('../assets/images/apple_icon.png');
        background-position: .5rem center;
        background-repeat: no-repeat;
        background-size: auto 50%;
        border-radius: 1.5rem;
        color: #fff;
        padding-left: 1.875rem;
    }
    .banner_num {
        position: absolute;
        font-size: .875rem;
        z-index: 2;
        color: #fff;
        bottom: 4.25rem;
        right: 2rem;
    }
    .dplayer-controller .dplayer-icons.dplayer-icons-right {
        display: none;
    }
</style>
