import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    reserve_info: {},
    store_info: null,
    user_list: []
};

// getters
const getters = {
    get_reserve_info(state) {
        return state.reserve_info;
    },
    get_store_info(state) {
        return state.store_info;
    },
    get_user_list(state) {
        return state.user_list;
    },
};

// actions
const actions = {
    getReserveInfo({ commit, rootState, rootGetters, dispatch }, rid) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/reserve/info?rid=' + rid, {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);
            if (res.data.code == 100000) {
                commit('RESERVE_SET_INFO', res.data.data);
                let { user_list } = res.data.data;
                dispatch('getUserList', {
                    user_list: user_list.join(','),
                    from_time: res.data.data.create_time
                });
            }
        });
    },
    getUserList({ commit, rootState, rootGetters }, { user_list, from_time } ) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/waitlist/customers?phone_num_list=' + encodeURIComponent(user_list) + '&from_time=' + from_time, {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);
            if (res.data.code == 100000) {
                commit('RESERVE_SET_USER_LIST', res.data.data.list);
            }
        });
    },
};

// mutations
const mutations = {
    RESERVE_SET_INFO(state, _data) {
        state.store_info = _data.store_info;
        delete _data.store_info;
        state.reserve_info = _data;
    },
    RESERVE_SET_USER_LIST(state, _data) {
        state.user_list = _data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
