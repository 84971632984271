import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    content_info: null,
};

// getters
const getters = {
    get_content_info(state) {
        return state.content_info;
    },
};

// actions
const actions = {
    getContentInfo({ commit, rootState, rootGetters, dispatch }, id) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/content/info?pid=' + id, {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);
            if (res.data.code == 100000) {
                commit('CONTENT_INFO_SET', res.data.data);
            }
        });
    }
};

// mutations
const mutations = {
    CONTENT_INFO_SET(state, _data) {
        state.content_info = _data.info;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
