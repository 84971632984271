import axios from 'axios'

const state = {
    debug: window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost' || window.location.href.indexOf('http://1') === 0,
}

const service = axios.create({
    baseURL: state.debug ? 'http://127.0.0.1:5050' : '',
    timeout: 300000,
})

service.interceptors.response.use(
    (response) => {
        // console.log('输出 ~ response', response)
        return response
    },
    (error) => {
        console.log('输出 ~ error', error)
        return Promise.reject(error)
    }
)

export default service
