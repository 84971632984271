import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const DEFAULT_LANG = 'eng';

const locales = {
    chn: require('./chn.json'),
    eng: require('./eng.json'),
    kor: require('./kor.json')
};

const i18n = new VueI18n({
    locale: DEFAULT_LANG,
    messages: locales
});

export const setLang = (lang) => {
    Object.keys(locales).forEach((lang) => {
        document.body.classList.remove(`lang-${lang}`);
    });
    document.body.classList.add(`lang-${lang}`);
    document.body.setAttribute('lang', lang);

    Vue.config.lang = lang || DEFAULT_LANG;
    i18n.locale = lang;
};

export default i18n;
