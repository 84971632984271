import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    coupon_info: {},
    store_info: {}
};

// getters
const getters = {
    get_coupon_info(state) {
        return state.coupon_info;
    },
    get_store_info(state) {
        return state.store_info;
    },
};

// actions
const actions = {
    getCouponInfo({ commit, rootState, rootGetters, dispatch }, token) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/coupon/info?token=' + encodeURIComponent(token), {
            method: 'GET'
        }).then((res) => {
            return res.text();
        })
        .then((res) => {
            res = JSON.parse(res);
            if (res.data.code == 100000) {
                commit('COUPON_SET_INFO', res.data.data);
            }
        });
    },
};

// mutations
const mutations = {
    COUPON_SET_INFO(state, _data) {
        state.store_info = _data.store_info;
        delete _data.store_info;
        state.coupon_info = _data.coupon_info;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
