<template lang="html">
    <div>
        <div id="app">
            <router-view></router-view>
        </div>
    </div>
</template>
<style>
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { setLang } from '@/locales';

    let HAS_TOAST = false;

    export default {
        name: 'app',
        data() {
            return {};
        },
        beforeUpdate() {},
        updated() {},
        beforeCreate() {},
        created() {
        },
        watch: {
            '$route'() {
                window['__logger__'] && window['__logger__']({
                    view: 1,
                    click: 0
                });
            }
        },
        computed: {
            ...mapGetters({
                msg: 'app/get_app_msg'
            }),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions({
                initData: 'app/initData',
            }),
            ...mapMutations({
            })
        }
    };
</script>
