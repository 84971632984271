import axiosApi from './axiosApi'
import wx from 'weixin-js-sdk'

export class Wechat {
    constructor(jsApiList = ['updateAppMessageShareData', 'updateTimelineShareData', 'closeWindow', 'onMenuShareAppMessage']) {
        this.isWeiXin = this.isInWx()
        this.wxJssdkInfo = {}
        this.jsApiList = jsApiList
    }

    isInWx() {
        return navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1 ? true : false
    }

    async _initJssdk(callback) {
        try {
            let auth_url = encodeURIComponent(location.href.split('#')[0])
            let params = { url: auth_url }
            this.wxJssdkInfo = await getWxAuth(params)
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: this.wxJssdkInfo.app_id, // 必填，公众号的唯一标识
                timestamp: this.wxJssdkInfo.timestamp, // 必填，生成签名的时间戳
                nonceStr: this.wxJssdkInfo.noncestr, // 必填，生成签名的随机串
                signature: this.wxJssdkInfo.signature, // 必填，签名
                jsApiList: this.jsApiList, // 必填，需要使用的JS接口列表
            })

            if (callback) {
                callback(this.wxJssdkInfo)
            }
        } catch (err) {
            console.log('输出 ~ err', err)
        }
    }
    share(title, desc, link, imgUrl, successCallback) {
        link = link || window.location.href
        if (!this.isWeiXin) return
        this._initJssdk(() => {
            wx.ready(() => {
                wx.checkJsApi({
                    jsApiList: this.jsApiList, // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function (res) {
                        if (res.checkResult.updateAppMessageShareData || res.checkResult.updateTimelineShareData) {
                            wx.updateAppMessageShareData({
                                title,
                                desc,
                                link,
                                imgUrl,
                                // success: (res) => {
                                //     console.log('输出 ~ updateAppMessageShareData 分享成功', res)
                                // },
                                // fail: (error) => {
                                //     console.log('updateAppMessageShareData error:', error)
                                // },
                            })
                            wx.updateTimelineShareData({
                                title,
                                link,
                                imgUrl,
                                // success: (res) => {
                                //     console.log('输出 ~ updateTimelineShareData 分享成功', res)
                                // },
                                // fail: (error) => {
                                //     console.log('updateTimelineShareData error:', error)
                                // },
                            })
                            wx.onMenuShareAppMessage({
                                title,
                                desc,
                                link,
                                imgUrl,
                                // success: (res) => {
                                //     console.log('输出 ~ onMenuShareAppMessage 分享成功', res)
                                // },
                                // fail: (error) => {
                                //     console.log('onMenuShareAppMessage error:', error)
                                // },
                            })
                        }
                    },
                })
            })
        })
    }

    // closeH5() {
    //     wx.closeWindow()
    // }
}

function getWxAuth(params) {
    return new Promise((resolve, reject) => {
        axiosApi
            .get('/weapp/wechat/query_wx_signature', { params })
            .then((res) => {
                let data = res.data.data.data
                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
